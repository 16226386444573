import stickyNav from './stickyNav';

export default () => {
  const navigation = document.querySelector('.navigation__inner');
  const drops = navigation.querySelectorAll('.dropdown');
  drops.forEach((elem) => elem.parentElement.classList.add('has-dropdown'));
  stickyNav();

  if (window.matchMedia('(max-width: 991px)').matches) {
    const dropTitle = navigation.querySelectorAll('.has-dropdown > a');
    if (!document.querySelector('.back-track')) {
      dropTitle.forEach((elem) => {
        const myClone = elem.cloneNode(true);
        myClone.classList.add('back-track');
        const wrapper = document.createElement('li');
        wrapper.appendChild(myClone);
        const myCloneParent = elem.nextElementSibling;
        myCloneParent.insertBefore(wrapper, myCloneParent.firstChild);
      });
    }
    const backLinks = navigation.querySelectorAll('.back-track');

    dropTitle.forEach((item) =>
      item.addEventListener('click', (evt) => {
        evt.preventDefault();
        item.nextElementSibling.classList.add('is-active');
      })
    );

    backLinks.forEach((item) =>
      item.addEventListener('click', (evt) => {
        evt.preventDefault();
        item.parentElement.parentElement.classList.remove('is-active');
      })
    );
  } else {
    document.querySelectorAll('.back-track').forEach((item) => {
      const parent = item.parentElement;
      parent.remove();
    });
  }
};
