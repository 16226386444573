import tippy from 'tippy.js';

export default () => {
  let placementValue = 'bottom';

  if (window.screen.width > 960) {
    placementValue = 'right';
  }

  tippy('[data-tippy-content]', {
    trigger: 'click',
    placement: placementValue,
    theme: 'travod',
    maxWidth: 300,
    offset: [0, 16],
    allowHTML: true,
  });

  tippy('[data-tippy-hover="true"]', {
    trigger: 'mouseenter',
    placement: 'bottom',
    theme: 'travod-tiny',
    maxWidth: 300,
    offset: [0, 16],
    allowHTML: true,
  });
};
