const env = {
  debug: process.env.NODE_ENV !== 'production',
  dev: process.env.NODE_ENV === 'development',
};

const checkVisability = (target, func, i, ioParams = {}) => {
  const io = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const el = entry.target;
        el.classList.add('is-loaded');

        func(el, i);

        observer.disconnect();
      }
    });
  }, ioParams);

  io.observe(target);
};

const tabs = (navItems, tabContentItems, mq = false) => {
  const btns = document.querySelectorAll(`${navItems}`);
  const tabsContent = document.querySelectorAll(`${tabContentItems}`);

  if (!btns.length || !tabsContent.length) {
    return false;
  }

  let activeTab;
  const setActiveTab = (activeTabName) => {
    tabsContent.forEach((tab) => {
      tab.classList.remove('is-active');
      if (tab.id === activeTabName) {
        tab.classList.add('is-active');
      }
    });
  };

  const setActiveNavItem = (evt) => {
    const { target } = evt;
    btns.forEach((item) => {
      item.classList.remove('is-active');
    });
    target.classList.add('is-active');
    activeTab = target.getAttribute('data-name');
    setActiveTab(activeTab);
  };

  const removeActiveElements = () => {
    btns.forEach((item) => {
      item.classList.remove('is-active');
    });
    tabsContent.forEach((tab) => {
      tab.classList.remove('is-active');
    });
  };

  const breakpoint = window.matchMedia(`(${mq})`);

  const checkBreakpoint = () => {
    if (breakpoint.matches) {
      removeActiveElements();
      if (btns.length && tabsContent.length) {
        btns[0].classList.add('is-active');
        tabsContent[0].classList.add('is-active');
      }
      btns.forEach((btn) => {
        btn.onclick = setActiveNavItem;
      });
    } else {
      removeActiveElements();
      btns.forEach((btn) => {
        btn.onclick = null;
      });
    }
  };
  if (mq) {
    checkBreakpoint();
    breakpoint.addListener(checkBreakpoint);
  } else if (btns.length && tabsContent.length) {
    removeActiveElements();
    btns[0].classList.add('is-active');
    tabsContent[0].classList.add('is-active');
    btns.forEach((btn) => {
      btn.onclick = setActiveNavItem;
    });
  }
};

const debounce = (callback, wait) => {
  let timeout = null;
  return (...args) => {
    const next = () => callback(args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
};

const smoothScroll = (elem, duration) => () => {
  const target = elem;
  const targetPosition = target.getBoundingClientRect().top;
  const startPosition = window.pageYOffset;
  const distance = targetPosition - startPosition;
  let startTime = null;

  function ease(t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
      return (c / 2) * t * t + b;
    }
    t -= 1;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  function animation(currentTime) {
    if (startTime === null) {
      startTime = currentTime;
    }
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) {
      requestAnimationFrame(animation);
    }
  }

  requestAnimationFrame(animation);
};

export { env, checkVisability, debounce, tabs, smoothScroll };
