import Swiper from 'swiper';

export default () => {
  const slideShow = document.querySelector('.js-technology-swiper');
  if (!slideShow) return false;

  let swiper;
  const enableSwiper = () => {
    swiper = new Swiper('.js-technology-swiper', {
      a11y: true,
      keyboardControl: true,
      grabCursor: true,
      slidesPerView: 1.4,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 2.8,
        },
      },
    });
  };

  const breakpoint = window.matchMedia('(min-width:992px)');

  const breakpointChecker = () => {
    if (breakpoint.matches) {
      if (swiper !== undefined) {
        swiper.destroy();
      }
      return;
    }
    return enableSwiper();
  };
  if (slideShow) {
    breakpointChecker();
    breakpoint.addListener(breakpointChecker);
  }
};