import Swiper from 'swiper';

export default () => {
  const slideShow = document.querySelector('#js-industry-benefits-swiper');
  if (!slideShow) return;

  let swiper;
  const enableSwiper = () => {
    swiper = new Swiper('#js-industry-benefits-swiper', {
      a11y: true,
      keyboardControl: true,
      grabCursor: true,
      spaceBetween: 10,
      slidesPerView: 1.6,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        560: {
          slidesPerView: 2.3,
        },
        700: {
          slidesPerView: 2.7,
        },
      },
    });
  };
  const breakpoint = window.matchMedia('(min-width:768px)');

  const breakpointChecker = () => {
    if (breakpoint.matches) {
      if (swiper !== undefined) {
        swiper.destroy();
      }
      return;
    }
    return enableSwiper();
  };
  if (slideShow) {
    breakpointChecker();
    breakpoint.addListener(breakpointChecker);
  }
};
