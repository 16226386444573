export default () => {
  const switcher = document.querySelector('.langs');
  if (!switcher) return false;
  const btn = switcher.querySelector('.langs__btn');
  const langsList = document.querySelector('.langs-list');
  const overlay = document.querySelector('.mobile-overlay');

  const hideLangsList = () => {
    overlay.classList.add('hidden');
    langsList.classList.add('hidden');
    overlay.removeEventListener('click', hideLangsList);
  };
  const showLangsList = () => {
    overlay.classList.remove('hidden');
    langsList.classList.remove('hidden');
    overlay.addEventListener('click', hideLangsList);
  };
  if (window.matchMedia('(max-width: 991px)').matches) {
    btn.onclick = showLangsList;
  } else {
    btn.onclick = null;
  }
};
