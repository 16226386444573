/* eslint-disable no-restricted-syntax */
export default () => {
  const tables = document.querySelectorAll('.text-block table');
  if (!tables.length) {
    return false;
  }

  const wrap = (el, wrapper) => {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
    wrapper.classList.add('table-block', 'lg:my-60p');
  };

  for (const table of tables) {
    wrap(table, document.createElement('div'));
  }
};
