import Swiper from 'swiper';

export default () => {
  const slideShow = document.querySelectorAll('.js-testimonials-carousel');
  if (!slideShow.length) return;

  return new Swiper('.js-testimonials-carousel', {
    loop: true,
    a11y: true,
    keyboardControl: true,
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 1.2,
    autoplay: {
      delay: 8000,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1.5,
      },
      992: {
        slidesPerView: 1,
      },
    },
  });
};
