export default () => {
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  if (viewportWidth > 992) {
    const searchForm = document.querySelector('#js-search-form');
    if (!searchForm) return false;
    const searchFormBtn = document.querySelector('#js-search-form #js-button');

    searchFormBtn.addEventListener('click', (evt) => {
      if (!searchForm.classList.contains('is-active')) {
        evt.preventDefault();
      }
    });

    document.addEventListener('click', (evt) => {
      let targetElement = evt.target;

      do {
        if (targetElement === searchForm) {
          searchForm.classList.add('is-active');
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);

      searchForm.classList.remove('is-active');
    });
  }
};
