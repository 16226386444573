export default () => {
  const body = document.querySelector('body');
  const nav = document.querySelector('.navigation');
  const navInner = nav.querySelector('.navigation__inner');
  const openNav = nav.querySelector('.nav-toggler:not(.cross)');
  const closeNav = nav.querySelector('.nav-toggler.cross');
  const overlay = document.querySelector('.js-overlay');

  if (!nav || !openNav) {
    return false;
  }

  const overlayHandler = () => {
    overlay.addEventListener('click', () => {
      if (navInner.classList.contains('is-open')) {
        navInner.classList.remove('is-open');
        overlay.classList.add('hidden');
      }
    });
  };

  const closeMobileNav = () => {
    navInner.classList.remove('is-open');
    closeNav.removeEventListener('click', closeMobileNav);
    body.classList.remove('no-scroll');
    overlay.classList.add('hidden');
  };
  const openMobileNav = () => {
    navInner.classList.add('is-open');
    closeNav.addEventListener('click', closeMobileNav);
    body.classList.add('no-scroll');
    overlay.classList.remove('hidden');
  };
  if (window.matchMedia('(max-width: 991px)').matches) {
    openNav.onclick = openMobileNav;
    overlayHandler();
  } else if (openNav) {
    openNav.onclick = null;
  }
};
