/**
 * You can use this function for tabs and accordions
 * Use valid css selector in the data-target param of buttons.
 *
 * @param accordeonId {string} '#accordion'
 * @param mq {string} css media query like 'max-width: 992px'
 * @param openedPen {number} number of default open pen, starts from 1
 * @param onlyOneActive {boolean} if true, only one opened pen can be in accordeon at the moment
 *
 * */
export default (accordeonId, mq = false, openedPen = null, onlyOneActive = false) => {
  const accordeon = document.querySelector(`${accordeonId}`);
  if (!accordeon) {
    return false;
  }

  const togglers = accordeon.querySelectorAll('.js-accordeon-btn');
  const pens = accordeon.querySelectorAll('.js-accordeon-content');

  if (openedPen) {
    togglers[openedPen - 1].classList.add('is-active');
    pens[openedPen - 1].classList.add('is-active');
  }

  const openNextStepPen = () => {
    const stepBtns = document.querySelectorAll('.js-step-btn');
    let id;
    let nextPen;
    stepBtns.forEach((item) => {
      item.addEventListener('click', (evt) => {
        const { target } = evt;
        // const parent = item.parentElement;
        // const prevSibling = parent.previousElementSibling;

        id = target.getAttribute('data-name');

        togglers.forEach((btn) => {
          if (btn.getAttribute('data-name') === id) {
            btn.classList.add('is-active');
            nextPen = btn.parentElement;
          }
        });
        pens.forEach((pen) => {
          if (pen.getAttribute('data-id') === id) {
            pen.classList.add('is-active');
          }
        });
        // setTimeout(() => {
        //   parent.classList.remove('is-active');
        //   prevSibling.classList.remove('is-active');
        // }, 1000);
        // smoothScroll(nextPen, 500);
        if (nextPen) {
          nextPen.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
        }
      });
    });
  };
  // if accordeon have step buttons logic
  if (document.querySelector('.js-step-btn')) {
    openNextStepPen();
  }

  const setActiveTab = (activeTabName) => {
    pens.forEach((tab) => {
      // if only one active pen need, renove oter pens active class
      if (onlyOneActive) {
        tab.classList.remove('is-active');
      }
      if (tab.getAttribute('data-id') === activeTabName) {
        tab.classList.add('is-active');
      }
    });
  };

  const setActiveNavItem = (evt) => {
    const { currentTarget } = evt;
    // take name of current tab
    setActiveTab(currentTarget.getAttribute('data-name'));
    if (currentTarget.classList.contains('is-active')) {
      // currentTarget.classList.remove('is-active');
      currentTarget.nextElementSibling.classList.remove('is-active');
    }
    // if only one pen need, remove other btns active class
    if (onlyOneActive) {
      togglers.forEach((item) => {
        item.classList.remove('is-active');
      });
    }
    currentTarget.classList.toggle('is-active');
  };

  const removeActiveElements = () => {
    togglers.forEach((item) => {
      item.classList.remove('is-active');
    });
    pens.forEach((tab) => {
      tab.classList.remove('is-active');
    });
  };

  const breakpoint = window.matchMedia(`(${mq})`);

  const checkBreakpoint = () => {
    if (breakpoint.matches) {
      removeActiveElements();
      togglers.forEach((btn) => {
        btn.onclick = setActiveNavItem;
      });
      if (openedPen) {
        togglers[openedPen - 1].classList.add('is-active');
        pens[openedPen - 1].classList.add('is-active');
      }
    } else {
      removeActiveElements();
      togglers.forEach((btn) => {
        btn.onclick = null;
      });
    }
  };

  if (mq) {
    checkBreakpoint();
    breakpoint.addListener(checkBreakpoint);
  } else {
    removeActiveElements();
    togglers.forEach((btn) => {
      btn.onclick = setActiveNavItem;
    });
  }
};
