import Swiper from 'swiper';

export default () => {
  const swiperItems = document.querySelectorAll('.js-simple-carousel');

  if (!swiperItems.length) return false;

  swiperItems.forEach(
    (elem) =>
      new Swiper(elem, {
        observer: true,
        observeParents: true,
        nested: true,
        loop: true,
        a11y: true,
        keyboardControl: true,
        grabCursor: true,
        centeredSlides: true,
        autoHeight: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      })
  );
};
