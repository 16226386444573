export default () => {
  const body = document.querySelector('body');

  if (body.classList.contains('no-sticky-nav') || !window.matchMedia('(min-width: 1024px)').matches) {
    return false;
  }
  const header = document.getElementById('js-page-header');
  const navBar = document.getElementById('js-top-nav-bar');
  const nav = navBar.querySelector('.js-navigation');
  const logo = navBar.querySelector('.js-logo');
  const navBlock = navBar.querySelector('.js-nav-block');
  const ctaList = navBar.querySelector('.js-header-cta-list');
  const ctaLangs = navBar.querySelector('.js-langs');
  const ctaListLinks = navBar.querySelectorAll('.js-link');
  const logoWhite = header.querySelector('.logo-white');
  const logoColor = header.querySelector('.logo-color');

  const ioParams = { rootMargin: '0px' };
  const io = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        navBar.classList.remove('is-sticky', 'shadow');
        nav.classList.remove('is-tiny');

        nav.classList.add('pt-60p');
        logo.classList.add('w-200p', 'lg:w-160p', 'xl:w-200p');
        logo.classList.remove('w-160p', 'lg:w-130p', 'xl:w-130p');

        ctaList.classList.add('lg:absolute', 'lg:top-0', 'lg:right-20p');
        navBlock.classList.remove('lg:flex');

        if (!nav.classList.contains('js-is-inverse')) {
          nav.classList.remove('is-inverse');
          logo.classList.add('text-white');
          logo.classList.remove('text-blue');
          navBlock.classList.add('text-white');
          navBlock.classList.remove('text-black');

          if (ctaLangs !== null) {
            ctaLangs.classList.remove('lg:text-black');
            ctaLangs.classList.add('lg:text-white');
          }

          for (const link of ctaListLinks) {
            link.classList.add('text-white');
            link.classList.remove('text-black');
          }

          if (body.classList.contains('wordminds-site') && logoWhite !== null && logoColor !== null) {
            logoWhite.classList.remove('hidden');
            logoColor.classList.add('hidden');
          }
        }
      } else {
        navBar.classList.add('is-sticky', 'shadow');
        nav.classList.add('is-inverse');
        nav.classList.add('is-tiny');

        nav.classList.remove('pt-60p');

        logo.classList.add('w-160p', 'lg:w-130p', 'xl:w-130p');
        logo.classList.remove('w-200p', 'lg:w-160p', 'xl:w-200p');

        ctaList.classList.remove('lg:absolute', 'lg:top-0', 'lg:right-20p');
        navBlock.classList.add('lg:flex');

        if (!nav.classList.contains('js-is-inverse')) {
          logo.classList.remove('text-white');
          logo.classList.add('text-blue');
          navBlock.classList.remove('text-white');
          navBlock.classList.add('text-black');

          if (ctaLangs !== null) {
            ctaLangs.classList.remove('lg:text-white');
            ctaLangs.classList.add('lg:text-black');
          }

          for (const link of ctaListLinks) {
            link.classList.remove('text-white');
            link.classList.add('text-black');
          }

          if (body.classList.contains('wordminds-site') && logoWhite !== null && logoColor !== null) {
            logoWhite.classList.add('hidden');
            logoColor.classList.remove('hidden');
          }
        }
      }
    });
  }, ioParams);

  io.observe(header);
};
