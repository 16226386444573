export default (tabId, mq = false) => {
  const tabs = document.querySelector(`${tabId}`);
  if (!tabs) {
    return false;
  }

  const btns = tabs.querySelectorAll('.js-tab-btn');
  const tabsContent = tabs.querySelectorAll('.js-item');

  let activeTab;
  const setActiveTab = (activeTabName) => {
    tabsContent.forEach((tab) => {
      tab.classList.remove('is-active');
      if (tab.id === activeTabName) {
        tab.classList.add('is-active');
      }
    });
  };

  const setActiveNavItem = (evt) => {
    const { currentTarget } = evt;
    btns.forEach((item) => {
      item.classList.remove('is-active');
    });
    currentTarget.classList.add('is-active');
    activeTab = currentTarget.getAttribute('data-name');
    setActiveTab(activeTab);
  };

  const removeActiveElements = () => {
    btns.forEach((item) => {
      item.classList.remove('is-active');
    });
    tabsContent.forEach((tab) => {
      tab.classList.remove('is-active');
    });
  };

  const breakpoint = window.matchMedia(`(${mq})`);
  // if true, remove active tab items and remove listeners
  const checkBreakpoint = () => {
    if (breakpoint.matches) {
      removeActiveElements();
      btns.forEach((btn) => {
        btn.onclick = null;
      });
    } else {
      removeActiveElements();
      btns[0].classList.add('is-active');
      tabsContent[0].classList.add('is-active');
      btns.forEach((btn) => {
        btn.onclick = setActiveNavItem;
      });
    }
  };

  if (mq) {
    checkBreakpoint();
    breakpoint.addListener(checkBreakpoint);
  } else {
    removeActiveElements();
    btns[0].classList.add('is-active');
    tabsContent[0].classList.add('is-active');
    btns.forEach((btn) => {
      btn.onclick = setActiveNavItem;
    });
  }
};
