import testimonials from './testimonials';
import industries from './industries';
import technologyProcess from './technology-process';
import industryBenefits from './industry-benefits';
import travodLeaders from './travod-leaders';
import aboutUsSwiper from './about-us-swiper';
import simple from './simple';
import { env } from '../../helpers';

export default () => {
  if (env.dev) {
    setTimeout(() => {
      industries();
      testimonials();
      technologyProcess();
      industryBenefits();
      travodLeaders();
      simple();
      aboutUsSwiper();
    }, 3000);
  } else {
    industries();
    testimonials();
    technologyProcess();
    industryBenefits();
    travodLeaders();
    simple();
    aboutUsSwiper();
  }
};
