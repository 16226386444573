export default (btnClassName, onlyOneActive = false) => {
  const buttons = document.querySelectorAll(`${btnClassName}`);
  const addHandler = (evt) => {
    const button = evt.currentTarget;
    const content = button.nextElementSibling;
    const parent = button.parentElement;
    const getBlockName = btnClassName.slice(1, btnClassName.indexOf('__'));

    const getBEMSiblingClass = (currentParentElem) => {
      const parentElem = currentParentElem;
      const className = Array.from(parentElem.classList).filter((item) => item.includes(getBlockName));
      while (!className.length) {
        const newParent = currentParentElem.parentElement;
        return getBEMSiblingClass(newParent);
      }
      return `.${className}`;
    };
    const getSiblings = (elem) => {
      const siblings = document.querySelectorAll(`${getBEMSiblingClass(parent)}`);
      const result = [];
      // const siblings = elem.parentNode.children;
      for (let i = 0; i < siblings.length; i += 1) {
        if (siblings[i] !== elem) {
          result.push(siblings[i]);
        }
      }
      return result;
    };

    button.classList.toggle('is-active');
    if (button.classList.contains('is-active')) {
      if (onlyOneActive) {
        getSiblings(parent).forEach((elem) => {
          elem.classList.remove('is-active');
          const btn = elem.querySelector('.js-btn');
          if (btn !== button) {
            btn.classList.remove('is-active');
            btn.nextElementSibling.style.height = 0;
          }
        });
      }
      button.closest(getBEMSiblingClass(parent)).classList.add('is-active');
      content.style.height = `${content.scrollHeight}px`;
    } else {
      button.closest(getBEMSiblingClass(parent)).classList.remove('is-active');
      content.style.height = 0;
    }
  };
  buttons.forEach((button) => {
    if (window.matchMedia('(max-width: 991px)').matches) {
      button.onclick = addHandler;
    } else {
      button.onclick = null;
      const content = button.nextElementSibling;
      content.removeAttribute('style');
    }
  });
};
