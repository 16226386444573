import Swiper from 'swiper';

export default () => {
  let swiper;
  const slideShow = document.querySelector('.tab-content');
  if (!slideShow) return false;

  const enableSwiper = () => {
    swiper = new Swiper('.tab-content', {
      a11y: true,
      keyboardControl: true,
      effect: 'fade',
      slidesPerView: 1,
      autoHeight: true,
      fadeEffect: {
        crossFade: true,
      },
      thumbs: {
        swiper: {
          el: '.tab',
          slidesPerView: 4,
        },
      },
    });
  };

  const breakpoint = window.matchMedia('(min-width: 992px)');

  const breakpointChecker = () => {
    if (breakpoint.matches) {
      enableSwiper();
    } else if (swiper !== undefined) {
      swiper.destroy();
    }
  };
  if (slideShow) {
    breakpointChecker();
    breakpoint.addListener(breakpointChecker);
  }
};
