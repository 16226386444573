// import { init } from '@sentry/browser';
import 'lazysizes';
import carousels from './components/carousels';
import accordion from './components/footer/navigation';
import nav from './components/navigation/index';
import navToggle from './components/navigation/navToggler';
import langSwitcher from './components/langSwitcher';
import tagSwither from './components/tagSwither';
import search from './components/search';
import newTabs from './components/tab';
import accordeon from './components/accordeon';
import swiperTabs from './components/swiperTabs';
import wrapTables from './components/wrapTables';
import loadMoreEntries from './components/loadMoreEntries';
import inputsWithRates from './components/inputsWithRates';
import { debounce } from './helpers';
import reframe from './components/reframe';
import tippy from './components/tippy';
import animateAnchorTextLinks from './components/animateAnchorTextLinks';

// Import our CSS
import '../css/base.scss';
import '../css/utils.scss';
import '../css/components.scss';
import { init } from '@sentry/browser'

init({
  dsn: 'https://95cc78df78b13738acb04680c0fda4e1@sentry.yournext.agency/163',
  ignoreErrors:[
    "Non-Error exception captured",
    "Non-Error promise rejection captured"
  ]
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept();
}

const callResizeDependent = () => {
  navToggle();
  nav();
  accordion('.services-we-choose__title', true);
  accordion('.services__title', true);
  search();

  langSwitcher();
  tagSwither('.js-tag-panel', '.js-overlay');
};

window.addEventListener(
  'resize',
  debounce(() => {
    callResizeDependent();
  }, 1000)
);

const initLoad = () => {
  swiperTabs('.swiper1', '.swiper2', 'max-width: 992px');
  newTabs('#join-team-tabs');
  newTabs('#js-services-tabs', 'max-width: 991px');

  accordeon('#become-translator-form', 'max-width: 992px', 1);
  accordeon('#become-vendor-form', 'max-width: 992px', 1);
  accordeon('#accordeon-contacts', 'max-width: 992px');
  accordeon('#js-footer-nav-list', 'max-width: 992px');
  wrapTables();
  loadMoreEntries();
  inputsWithRates();
  callResizeDependent();
  carousels();
  reframe();
  tippy();
  animateAnchorTextLinks();
};

document.addEventListener('DOMContentLoaded', initLoad);
