import Swiper from 'swiper';

export default () => {
  const slideShow = document.querySelector('#js-about-us-benefits-swiper');
  let swiper;
  const enableSwiper = () => {
    swiper = new Swiper(slideShow, {
      spaceBetween: 15,
      a11y: true,
      keyboardControl: true,
      grabCursor: true,
      slidesPerView: 1.4,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  };
  const breakpoint = window.matchMedia('(min-width:768px)');

  const breakpointChecker = () => {
    if (breakpoint.matches) {
      if (swiper !== undefined) {
        swiper.destroy();
      }
    } else {
      enableSwiper();
    }
  };
  if (slideShow) {
    breakpointChecker();
    breakpoint.addListener(breakpointChecker);
  }
};
