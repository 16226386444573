/* eslint-disable no-lonely-if */
import Swiper from 'swiper';

export default (navClass, viewClass, mq) => {
  const nav = document.querySelector(`${navClass}`);
  const view = document.querySelector(`${viewClass}`);

  if (!nav || !view) {
    return false;
  }

  const setCurrentSlide = (ele) => {
    document.querySelectorAll('.swiper1 .swiper-slide').forEach((item) => {
      item.classList.remove('selected');
    });
    ele.classList.add('selected');
  };
  let swiper1;
  let swiper2;
  const enableSwiper1 = () => {
    swiper1 = new Swiper(nav, {
      direction: 'vertical',
      slidesPerView: 'auto',
      spaceBetween: 15,
      loop: false,
      allowTouchMove: false,
      virtualTranslate: true,
    });
  };
  const enableSwiper2 = () => {
    swiper2 = new Swiper(view, {
      autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },
      autoHeight: true,
      grabCursor: true,
      breakpoints: {
        992: {
          width: 555,
        },
        1100: {
          width: 630,
        },
      },
      on: {
        init() {
          const n = this.activeIndex;
          const elem = document.querySelectorAll('.swiper1 .swiper-slide')[n];
          setCurrentSlide(elem, n);
        },

        slideChange() {
          const n = this.activeIndex;
          const elem = document.querySelectorAll('.swiper1 .swiper-slide')[n];
          setCurrentSlide(elem, n);
          swiper1.slideTo(n, 500, false);
        },
      },
    });
  };

  // resize
  const breakpoint = window.matchMedia(`(${mq})`);

  const stopAutoplay = (element) => () => {
    element.autoplay.stop();
  };
  const startAutoplay = (element) => () => {
    element.autoplay.start();
  };

  const breakpointChecker = () => {
    if (breakpoint.matches) {
      if (swiper1 !== undefined && swiper2 !== undefined) {
        // view.removeEventListener('mouseenter', stopAutoplay(swiper2));
        // view.removeEventListener('mouseleave', startAutoplay(swiper2));
        swiper1.destroy(true, true);
        swiper2.destroy(true, true);
      }
    } else {
      enableSwiper1();
      enableSwiper2();
      swiper1.update();
      view.addEventListener('mouseenter', stopAutoplay(swiper2));
      view.addEventListener('mouseleave', startAutoplay(swiper2));
      Array.from(swiper1.slides).forEach((item, index) => {
        item.addEventListener('click', () => {
          setCurrentSlide(item, index);
          swiper2.slideTo(index, 500, false);
        });
      });
    }
  };
  if (mq) {
    breakpointChecker();
    breakpoint.addListener(breakpointChecker);
  }
};
