export default () => {
  const inputs = document.querySelectorAll('.hidden-input > input');
  if (!inputs.length) return false;

  inputs.forEach((elem) => {
    elem.addEventListener('focus', () => {
      elem.placeholder = '0.00';
    });
    elem.addEventListener('blur', () => {
      const { holder } = elem.dataset;
      elem.placeholder = holder;
    });
  });
};
