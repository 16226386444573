export default (listId, buttonId, apiPath) => {
  const list = document.getElementById(listId);
  const loadMoreBtn = document.getElementById(buttonId);

  if (!list || !loadMoreBtn) {
    return false;
  }

  let currPage = 2;
  const { channel } = list.dataset;
  const totalPages = list.dataset.total;
  const { limit } = list.dataset;
  const { category } = list.dataset;

  async function getEntries() {
    const url = `${apiPath}?section=${channel}&limit=${limit}&currPage=${currPage}&category=${category}`;

    loadMoreBtn.classList.add('is-loading');
    loadMoreBtn.disabled = true;

    const response = await fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    if (response.ok) {
      const newElements = await response.text();
      list.insertAdjacentHTML('beforeend', newElements);
      currPage += 1;
      if (currPage > totalPages) {
        loadMoreBtn.classList.add('hidden');
      } else {
        loadMoreBtn.classList.remove('is-loading');
        loadMoreBtn.disabled = false;
      }
    } else {
      loadMoreBtn.classList.remove('is-loading');
      loadMoreBtn.disabled = false;
    }
  }

  loadMoreBtn.addEventListener('click', getEntries);
};
