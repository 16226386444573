/* eslint-disable no-restricted-syntax */
export default (tagPanelSelector, overlaySelector) => {
  const panels = document.querySelectorAll(tagPanelSelector);

  for (const panel of panels) {
    const overlay = document.querySelector(overlaySelector);
    const togglerId = panel.getAttribute('data-toggler-id');
    const toggler = document.getElementById(togglerId);
    if (!toggler) {
      return false;
    }

    const hidePanel = () => {
      overlay.classList.add('hidden');
      panel.classList.add('hidden');
      overlay.removeEventListener('click', hidePanel);
    };

    const showPanel = () => {
      overlay.classList.remove('hidden');
      panel.classList.remove('hidden');
      overlay.addEventListener('click', hidePanel);
    };

    if (window.matchMedia('(max-width: 991px)').matches) {
      toggler.onclick = showPanel;
    } else {
      toggler.onclick = null;
    }
  }
};
