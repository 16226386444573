import Swiper from 'swiper';

export default () => {
  const slides = document.querySelectorAll('#js-industies .js-slide');
  if (!slides.length) return false;

  const slidesToggler = document.querySelector('#js-industies .js-toggler');

  let industriesCarousel;
  const enableSwiper = () => {
    industriesCarousel = new Swiper('.js-industies-carousel', {
      roundLengths: true,
      loop: false,
      slidesPerView: 3,
      a11y: true,
      keyboardControl: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1440: {
          slidesPerView: 4,
        },
      },
    });
  };

  const breakpoint = window.matchMedia('(min-width:640px)');

  const breakpointChecker = () => {
    if (breakpoint.matches) {
      return enableSwiper();
    }
    if (industriesCarousel !== undefined) {
      industriesCarousel.destroy(true, true);
    }
  };

  if (slidesToggler) {
    slidesToggler.addEventListener('click', (evt) => {
      if (!slides.length) {
        return;
      }
      slides.forEach((el) => {
        el.classList.remove('hidden');
      });
      evt.currentTarget.classList.add('hidden');
    });
    breakpoint.addListener(breakpointChecker);
    breakpointChecker();
  }
};
